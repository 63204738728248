import React, { useEffect, useState } from "react";
import UserNavbar from "./UserNavbar";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDashboard } from "../contexts/DashboardContext";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  CartesianGrid,
  XAxis,
  YAxis,
  AreaChart,
  Area,
  Label,
  ResponsiveContainer,
} from "recharts";
import Map from "./Map";

const MasterDashboard = () => {
  const [systemsData, setSystemsData] = useState([]);
  const [totalAgentsCount, setTotalAgentsCount] = useState(0);
  const [activeAgentsCount, setActiveAgentsCount] = useState(0);
  const [gt10AlertsCount, setGt10AlertsCount] = useState(0);
  const [rootkitAlertsCount, setRootkitAlertsCount] = useState(0);
  const [fiimAlertsCount, setFiimAlertsCount] = useState(0);
  const [attackAlertsCount, setAttackAlertsCount] = useState(0);
  const [byAgentData, setByAgentData] = useState([]);
  const [byGroupData, setByGroupData] = useState([]);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [agentsInfo, setAgentsInfo] = useState([]);
  const [geoLocationData, setGeoLocationData] = useState([]);
  const [activeLevel, setActiveLevel] = useState(null);
  const { setAdmin, setActiveNav } = useDashboard();
  const token = Cookies.get("token");
  const customerid = Cookies.get("customerid");
  const username = Cookies.get("username");
  const role = Cookies.get("role");
  const navigate = useNavigate();

  const getAlertsDataByTypeOrGroup = (category, subcategory) => {
    return axios.get(`/api/system/systems/${category}/${subcategory}`, {
      params: {
        hours: 24,
      },
      headers: {
        "x-auth-token": token,
      },
    });
  };

  const getAlertsDataByChartType = (chartType) => {
    return axios.get(`/api/system/systems/${chartType}`, {
      params: {
        hours: 24,
      },
      headers: {
        "x-auth-token": token,
      },
    });
  };

  const getAgentInfo = (info) => {
    return axios.get(`/api/system/${info}`, {
      headers: {
        "x-auth-token": token,
      },
    });
  };

  useEffect(() => {
    if (token && role) {
      if (role === "1") {
        setAdmin(true);
      }
    }
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/signin");
    } else {
      axios
        .get("/api/token/isLoggedIn", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => console.log(res.data))
        .catch(() => navigate("/signin"));
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      axios
        .get(`/api/system/${customerid}`, {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setSystemsData(res.data.systems));
    }
  }, []);

  useEffect(() => {
    setActiveNav("main");
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAgentInfo("gettotalagentscount").then((res) =>
        setTotalAgentsCount(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAgentInfo("getactiveagentscount").then((res) =>
        setActiveAgentsCount(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAlertsDataByTypeOrGroup("type", "gt10").then((res) =>
        setGt10AlertsCount(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAlertsDataByTypeOrGroup("type", "rootcheck").then((res) =>
        setRootkitAlertsCount(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAlertsDataByTypeOrGroup("type", "syscheck").then((res) =>
        setFiimAlertsCount(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAlertsDataByTypeOrGroup("type", "attack").then((res) =>
        setAttackAlertsCount(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAlertsDataByTypeOrGroup("group", "byagent").then((res) =>
        setByAgentData(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAlertsDataByTypeOrGroup("group", "bygroup").then((res) =>
        setByGroupData(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAlertsDataByChartType("timeseriesbyagents").then((res) => {
        const agents = new Set();
        res.data.forEach((item) => {
          Object.keys(item).forEach((key) => {
            if (key !== "time") agents.add(key); // Collect all levels
          });
        });

        const agentsArray = Array.from(agents);

        // Normalize res.data: Ensure each timestamp has all levels, even if some levels are missing
        const normalizedData = res.data.map((item) => {
          // Add missing levels as null (or 0)
          agentsArray.forEach((agentName) => {
            if (!item.hasOwnProperty(agentName)) {
              item[agentName] = null; // Or set it to 0 depending on the requirement
            }
          });
          return item;
        });

        setTimeSeriesData(normalizedData); // Set the normalized data to state
      });
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAlertsDataByChartType("map").then((res) =>
        setGeoLocationData(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAgentInfo("agentsinfo").then((res) => setAgentsInfo(res.data));
    }
  }, []);

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    return `${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}`; // Format as HH:mm
  };

  const handleMouseEnter = (level) => {
    setActiveLevel(level); // Set the active level when hovering
  };

  const handleMouseLeave = () => {
    setActiveLevel(null); // Reset when mouse leaves
  };

  // Custom legend to show only the active level
  const customLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        className="recharts-default-legend"
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap", // This allows the legend items to wrap to the next line
          padding: 0, // Optional: Remove any default padding if necessary
          listStyle: "none", // Optional: Remove default list styles
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={index}
            style={{
              color: entry.color,
              fontWeight: entry.value === activeLevel ? "bold" : "normal", // Bold the active level
              scale: entry.value === activeLevel ? "1.1" : "1", // Bold the active level
              cursor: "pointer", // Make the legend items clickable
              marginRight: "15px", // Add spacing between legend items
              marginBottom: "5px", // Add spacing between rows (vertical gap)
              display: "flex",
              alignItems: "center", // Align icon and text horizontally
            }}
            onMouseEnter={() => handleMouseEnter(entry.value)} // Trigger hover effect
            onMouseLeave={handleMouseLeave} // Reset hover effect
          >
            <i class="ri-git-commit-line"></i>
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  const COLORS = [
    "#FF6F61", // Coral red
    "#4CAF50", // Green
    "#2196F3", // Blue
    "#FF9800", // Orange
    "#9C27B0", // Purple
    "#00BCD4", // Cyan
    "#212121", // Dark grey
    "#8E44AD", // Violet
    "#F39C12", // Amber
    "#2ECC71", // Emerald green
    "#E91E63", // Pink
    "#3F51B5", // Indigo
    "#212121", // Dark grey
    "#FF5722", // Deep orange
    "#9E9E9E", // Grey
    "#607D8B", // Blue-grey
    "#00BCD4", // Cyan
    "#FF1493", // Deep pink
    "#32CD32", // Lime green
    "#1E90FF", // Dodger blue
    "#D32F2F", // Red
    "#1976D2", // Blue
    "#388E3C", // Green
    "#0288D1", // Light blue
    "#FF4081", // Pink
    "#7B1FA2", // Deep purple
    "#212121", // Dark grey
    "#CD5C5C", // Indian red
    "#FF6347", // Tomato
    "#3F51B5", // Indigo
    "#FF4500", // Orange red
    "#F57C00", // Orange
    "#C2185B", // Pink
    "#7C4DFF", // Deep purple
    "#76FF03", // Lime green
    "#FF4081", // Pink
    "#00E5FF", // Bright cyan
    "#FF8A65", // Light orange
    "#00C853", // Green
    "#0288D1", // Light blue
    "#3D5AFE", // Blue
    "#F44336", // Red
    "#FF5722", // Deep orange
    "#9E9D24", // Olive green
    "#B3E5FC", // Light blue
    "#F44336", // Red
    "#FF9800", // Orange
    "#5C6BC0", // Indigo
    "#D32F2F", // Red
    "#009688", // Teal
    "#8E24AA", // Purple
    "#4CAF50", // Green
  ];

  const chartData =
    timeSeriesData !== "error" && timeSeriesData?.length > 0 && timeSeriesData;

  const MasterDashboardDiv = (
    <div className="px-4 lg:w-[80vw] xl:w-[85vw] lg:absolute lg:right-0 lg:px-6 xl:absolute xl:right-0 xl:px-16 xl:pt-20 xl:pb-12 xl:bg-gray-50">
      <h1 className="font-bold text-xl py-8 md:text-3xl">
        Welcome back, <span className="text-[#0055d2]">{username}</span>
      </h1>

      <div>
        <div className="md:flex lg:flex-col lg:items-center xl:flex-row">
          <div className="flex justify-between text-center gap-2 sm:flex-col sm:justify-center sm:w-[40%] sm:px-2 lg:flex-row lg:items-center lg:w-[60%] xl:w-[30%]">
            <div className="w-[50%] sm:w-full">
              <div className="font-semibold  xl:text-lg">Total Systems</div>
              <div className="text-2xl bg-[#0055d2] text-white py-4 rounded-sm mt-2 md:py-8 lg:py-8 lg:text-8xl">
                {totalAgentsCount}
              </div>
            </div>
            <div className="w-[50%] sm:w-full">
              <div className="font-semibold  xl:text-lg">Systems Online</div>
              <div className="text-2xl bg-[#6ab65d] text-white py-4 rounded-sm mt-2 md:py-8 lg:py-8 lg:text-8xl">
                {activeAgentsCount}
              </div>
            </div>
          </div>

          <div className="mt-6 sm:w-[60%] xl:w-[70%] sm:p-2">
            <h1 className="font-semibold sm:text-center md:text-xl xl:text-xl">
              Last 24 hours stats
            </h1>
            <div className="flex flex-wrap justify-between text-center gap-2 pt-4">
              <div className=" w-[48%] rounded-sm mb-4 lg:w-[48%] xl:w-[23%]  border border-black cursor-pointer">
                <h1 className="font-bold text-black py-2 lg:text-xs xl:text-lg">
                  Alert Level &gt; 10
                </h1>
                <div
                  className="text-4xl py-2 rounded-sm mt-2 text-red-500 font-extrabold sm:py-8 lg:text-5xl border-t border-black"
                  onClick={() => navigate("/logs/systems/gt10")}
                >
                  {gt10AlertsCount}
                </div>
              </div>
              <div className=" w-[48%] rounded-sm mb-4 lg:w-[48%] xl:w-[23%]  border border-black cursor-pointer">
                <h1 className="font-bold text-black py-2 lg:text-xs xl:text-lg">
                  Rootkit Alerts
                </h1>
                <div
                  className="text-4xl py-2 rounded-sm mt-2 text-red-500 font-extrabold sm:py-8 lg:text-5xl border-t border-black"
                  onClick={() => navigate("/logs/systems/rootcheck")}
                >
                  {rootkitAlertsCount}
                </div>
              </div>
              <div className=" w-[48%] rounded-sm mb-4 lg:w-[48%] xl:w-[23%]  border border-black cursor-pointer">
                <h1 className="font-bold text-black py-2 lg:text-xs xl:text-lg">
                  FIIM Alerts
                </h1>
                <div
                  className="text-4xl py-2 rounded-sm mt-2 text-red-500 font-extrabold sm:py-8 lg:text-5xl border-t border-black"
                  onClick={() => navigate("/logs/systems/syscheck")}
                >
                  {fiimAlertsCount}
                </div>
              </div>
              <div className=" w-[48%] rounded-sm mb-4 lg:w-[48%] xl:w-[23%]  border border-black cursor-pointer">
                <h1 className="font-bold text-black py-2 lg:text-xs xl:text-lg">
                  Attack Alerts
                </h1>
                <div
                  className="text-4xl py-2 rounded-sm mt-2 text-red-500 font-extrabold sm:py-8 lg:text-5xl border-t border-black"
                  onClick={() => navigate("/logs/systems/attack")}
                >
                  {attackAlertsCount}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="xl:flex xl:flex-col">
          <div className="xl:flex">
            <div className="w-full xl:pr-16 xl:flex xl:flex-col xl:justify-evenly">
              <div className="relative w-full text-[0.5rem] xl:text-[1rem]">
                {chartData !== "error" && chartData?.length > 0 ? (
                  <>
                    <ResponsiveContainer
                      aspect={1.75}
                      height="100%"
                      width="100%"
                      className="flex justify-center py-10"
                    >
                      <AreaChart data={chartData}>
                        <CartesianGrid stroke="#ccc" />
                        <XAxis
                          dataKey="time"
                          allowDuplicatedCategory={false}
                          tickFormatter={(tick) => formatDate(tick)} // Format to show only hours and minutes
                          type="category" // Treat X-axis as categorical (string-based dates)
                        />

                        <YAxis>
                          <Label
                            fill="black"
                            angle={270}
                            value="alerts count"
                            position={"insideLeft"}
                          />
                        </YAxis>
                        <Tooltip
                          labelFormatter={(value) => formatDate(value)}
                          content={({ active, payload, label }) => {
                            if (active && payload && payload.length) {
                              // Find the hovered level's data
                              const hoveredLevelData = payload.find(
                                (item) => item.dataKey === activeLevel
                              );

                              if (hoveredLevelData) {
                                const level = hoveredLevelData.dataKey; // Get the level (e.g., 'high', 'medium', 'low')
                                const value = hoveredLevelData.value; // Get the value for that level

                                return (
                                  <div
                                    className="custom-tooltip"
                                    style={{
                                      backgroundColor: "white",
                                      padding: "5px",
                                      border: "1px solid #ccc",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <p>
                                      <strong>Time:</strong> {formatDate(label)}
                                    </p>
                                    <p>
                                      <strong>
                                        {level.charAt(0).toUpperCase() +
                                          level.slice(1)}
                                        :
                                      </strong>{" "}
                                      {value}
                                    </p>
                                  </div>
                                );
                              }
                            }
                            return null;
                          }}
                        />
                        <Legend
                          content={customLegend}
                          layout="horizontal"
                          verticalAlign="top"
                          align="center"
                        />
                        <Label />

                        {Object.keys(chartData[0])
                          .filter((key) => key !== "time")
                          .map((systemName, index) => {
                            const color = COLORS[index % COLORS.length];

                            return (
                              <Area
                                key={systemName}
                                type="monotone"
                                dataKey={systemName}
                                stroke={color}
                                fill={color}
                                data={chartData} // Pass all data (no filtering)
                                fillOpacity={
                                  systemName === activeLevel ? 0.75 : 0.25
                                } // Highlight the hovered systemName
                                onMouseEnter={() =>
                                  handleMouseEnter(systemName)
                                } // Trigger hover effect
                                onMouseLeave={handleMouseLeave} // Reset on mouse leave
                                cursor="pointer"
                              />
                            );
                          })}
                      </AreaChart>
                    </ResponsiveContainer>
                    <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 mb-4">
                      <span className="text-black">time</span>
                    </div>
                  </>
                ) : (
                  <div className="flex justify-center items-center text-red-400">
                    No Data Found!
                  </div>
                )}
              </div>

              {agentsInfo.length > 0 ? (
                <div class="overflow-x-scroll overflow-y-auto max-h-[50vh] text-[0.75rem] xl:overflow-auto">
                  <table class="w-[100%] text-left border-separate border-spacing-0 border-black">
                    <thead className="border border-black sticky top-0 z-50 bg-gray-200">
                      <tr>
                        <th className="border border-black p-2">ID</th>
                        <th className="border border-black p-2">Name</th>
                        <th className="border border-black p-2">IP Address</th>
                        <th className="border border-black p-2">
                          Operating System
                        </th>
                        <th className="border border-black p-2">Status</th>
                        <th className="border border-black p-2">last seen</th>
                      </tr>
                    </thead>
                    <tbody>
                      {agentsInfo.map((data) => {
                        return (
                          <tr key={data.id} className="border border-black">
                            <td className="border border-black p-2">
                              {data.id}
                            </td>
                            <td className="border border-black max-w-max p-2">
                              <a
                                href={`/systemdashboard/${data.name}`}
                                className="text-[#0078a3] whitespace-nowrap"
                              >
                                {data.name}
                              </a>
                            </td>
                            <td className="border border-black whitespace-nowrap p-2">
                              {data.ip}
                            </td>
                            <td className="border border-black p-2">
                              {data.os ? data.os.name : "-"}{" "}
                              {data.os ? data.os.version : ""}
                            </td>

                            <td className="border border-black p-2">
                              <div></div>
                              {data.status}
                            </td>
                            <td className="border border-black whitespace-nowrap p-2">
                              {data.lastKeepAlive
                                ? new Date(data.lastKeepAlive).toLocaleString()
                                : "-"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  loading...
                </div>
              )}
            </div>

            <div className="w-full md:flex md:justify-around xl:flex-col">
              <div className="flex flex-col items-center w-full md:w-1/2 xl:w-full">
                <div className="my-2 xl:text-xl">
                  Top Systems Generating Alerts
                </div>
                <div className="w-full text-[0.5rem] xl:text-[1rem]">
                  {byAgentData !== "error" && byAgentData?.length > 0 ? (
                    <ResponsiveContainer aspect={1} width="100%" height={300}>
                      <PieChart>
                        <Legend
                          layout="vertical"
                          verticalAlign="top"
                          align="right"
                          wrapperStyle={{
                            fontSize: "0.5rem",
                            padding: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            height: "20vh",
                            overflowY: "auto",
                          }}
                          content={(props) => {
                            const { payload } = props;
                            return (
                              <ul
                                style={{
                                  listStyleType: "none",
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                {payload.map((entry, index) => (
                                  <li
                                    key={`item-${index}`}
                                    style={{
                                      color: "black", // Set legend text color to black
                                      fontSize: "0.5rem", // Optional: adjust the font size if needed
                                    }}
                                  >
                                    <span
                                      style={{
                                        display: "inline-block",
                                        width: "12px",
                                        height: "12px",
                                        backgroundColor: entry.payload.fill,
                                        marginRight: "8px",
                                      }}
                                    />
                                    {entry.value}
                                  </li>
                                ))}
                              </ul>
                            );
                          }}
                        />
                        <Pie
                          dataKey="doc_count"
                          nameKey="key"
                          isAnimationActive={false}
                          data={byAgentData}
                          innerRadius="50%"
                          outerRadius="100%"
                          stroke=""
                          fill="#8884d8"
                          labelLine={false}
                        >
                          {byAgentData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>

                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  ) : (
                    <div className="flex justify-center items-center text-red-400">
                      No Data Found!
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-col items-center w-full md:w-1/2 xl:w-full">
                <div className="my-2 xl:text-xl">Top Alert Groups</div>
                <div className="w-full text-[0.5rem] xl:text-[1rem]">
                  {byGroupData !== "error" && byGroupData?.length > 0 ? (
                    <ResponsiveContainer aspect={1} width="100%" height={325}>
                      <PieChart>
                        <Legend
                          layout="vertical"
                          verticalAlign="top"
                          align="right"
                          wrapperStyle={{
                            fontSize: "0.5rem",
                            padding: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            height: "20vh",
                            overflowY: "auto",
                          }}
                          content={(props) => {
                            const { payload } = props;
                            return (
                              <ul
                                style={{
                                  listStyleType: "none",
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                {payload.map((entry, index) => (
                                  <li
                                    key={`item-${index}`}
                                    style={{
                                      color: "black", // Set legend text color to black
                                      fontSize: "0.5rem", // Optional: adjust the font size if needed
                                    }}
                                  >
                                    <span
                                      style={{
                                        display: "inline-block",
                                        width: "12px",
                                        height: "12px",
                                        backgroundColor: entry.payload.fill,
                                        marginRight: "8px",
                                      }}
                                    />
                                    {entry.value}
                                  </li>
                                ))}
                              </ul>
                            );
                          }}
                        />
                        <Pie
                          dataKey="doc_count"
                          nameKey="key"
                          data={byGroupData}
                          innerRadius="50%"
                          outerRadius="100%"
                          stroke=""
                          fill="#8884d8"
                          labelLine={false}
                        >
                          {byGroupData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>

                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  ) : (
                    <div className="flex justify-center items-center text-red-400">
                      No Data Found!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full xl:flex xl:flex-col xl:items-center">
            <div className="my-2 xl:text-xl">Source IP Addresses</div>
            <div className="w-full">
              {geoLocationData !== "error" && geoLocationData?.length > 0 ? (
                <Map geoLocationData={geoLocationData} />
              ) : (
                <div className="flex justify-center items-center text-red-400">
                  No Data Found!
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="overflow-hidden lg:flex">
      <UserNavbar />

      {role !== "1" ? (
        <>
          {systemsData.length > 0 ? (
            MasterDashboardDiv
          ) : (
            <div className="px-4 gap-4 lg:w-[80vw] xl:w-[85vw] lg:absolute lg:right-0 lg:px-6 flex justify-center items-center xl:px-16 xl:pt-20 xl:pb-12 xl:bg-gray-50 h-[100vh]">
              No Agents Added
            </div>
          )}
        </>
      ) : (
        <div className="px-4 gap-4 lg:w-[80vw] xl:w-[85vw] lg:absolute lg:right-0 lg:px-6 flex justify-center items-center xl:px-16 xl:pt-20 xl:pb-12 xl:bg-gray-50 h-[100vh]">
          <div className="bg-[#030b3a] text-white p-4 rounded-md ">
            <a
              onClick={() => navigate("/addcustomer")}
              className="cursor-pointer"
            >
              Add Customer
            </a>
          </div>
          <div className="bg-[#030b3a] text-white p-4 rounded-md ">
            <a
              onClick={() => navigate("/addlicense")}
              className="cursor-pointer"
            >
              Add License
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default MasterDashboard;
