import React, { useEffect, useState } from "react";
import UserNavbar from "./UserNavbar";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { useDashboard } from "../contexts/DashboardContext";

const Reports = () => {
  const [systemsData, setSystemsData] = useState([]);
  const [scaAlertsData, setScaAlertsData] = useState([]);
  const [postsPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [allAlertsData, setAllAlertsData] = useState([]);
  const [node, setNode] = useState("");
  const [timeFrame, setTimeFrame] = useState("24h");
  const [dropdownIndex, setDropdownIndex] = useState(-1);
  const [alertTab, setAlertTab] = useState(1);
  const [tableTab, setTableTab] = useState("table");
  const [scaAlertsDataloading, setScaAlertsDataLoading] = useState(true);
  const [allAlertsDataloading, setAllAlertsDataLoading] = useState(true);
  const token = Cookies.get("token");
  const customerid = Cookies.get("customerid");
  const navigate = useNavigate();
  const { setActiveNav } = useDashboard();

  let timeFrameData = ["1h", "12h", "24h", "7d", "30d"];

  useEffect(() => {
    if (!token) {
      navigate("/signin");
    } else {
      axios
        .get("/api/token/isLoggedIn", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => console.log(res.data))
        .catch(() => navigate("/signin"));
    }
  }, []);

  useEffect(() => {
    setActiveNav("reports");
  }, []);

  useEffect(() => {
    axios
      .get(`/api/system/${customerid}`, {
        headers: {
          "x-auth-token": token,
        },
      })
      .then((res) => setSystemsData(res.data.systems));
  }, []);

  useEffect(() => {
    if (node.length > 0) {
      axios
        .get(`/api/system/node/${node}/getscaalertsdata`, {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => {
          setScaAlertsData(
            res.data.sort(
              (a, b) =>
                new Date(b._source.timestamp) - new Date(a._source.timestamp)
            )
          );
        })
        .then(() => setScaAlertsDataLoading(false))
        .catch((err) => console.log(err));
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (node.length > 0) {
      axios
        .get(`/api/system/node/${node}/gettotalalertsdata`, {
          params: {
            hours: timeFrame,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => {
          setAllAlertsData(
            res.data.sort(
              (a, b) =>
                new Date(b._source.timestamp) - new Date(a._source.timestamp)
            )
          );
        })
        .then(() => setAllAlertsDataLoading(false))
        .catch((err) => console.log(err));
    }
  }, [node, timeFrame]);

  useEffect(() => {
    setCurrentPage(1);
  }, [alertTab]);

  const handleChange = (event) => {
    setNode(event.target.value);
  };
  const handleTimeFrameChange = (event) => {
    setTimeFrame(event.target.value);
  };

  const showTableTabs = (item) => {
    if (tableTab === "table") {
      return (
        <div className="p-6 text-lg">
          <table className="table-fixed">
            <tbody className="text-center">
              {item._source.agent &&
                Object.entries(item._source.agent).map((entry) => {
                  let key = entry[0];
                  let value = entry[1];
                  return (
                    <tr>
                      <td className="p-2">agent.{key}</td>
                      <td>{value}</td>
                    </tr>
                  );
                })}
              {item._source.GeoLocation &&
                item._source.GeoLocation.location &&
                Object.entries(item._source.GeoLocation.location).map(
                  (entry) => {
                    let key = entry[0];
                    let value = entry[1];
                    return (
                      <tr>
                        <td className="p-2">GeoLocation.{key}</td>
                        <td>{value}</td>
                      </tr>
                    );
                  }
                )}

              {item._source.decoder &&
                Object.entries(item._source.decoder).map((entry) => {
                  let key = entry[0];
                  let value = entry[1];
                  return (
                    <tr>
                      <td className="p-2">decoder.{key}</td>
                      <td>{value}</td>
                    </tr>
                  );
                })}
              {item._source.input &&
                Object.entries(item._source.input).map((entry) => {
                  let key = entry[0];
                  let value = entry[1];
                  return (
                    <tr>
                      <td className="p-2">input.{key}</td>
                      <td>{value}</td>
                    </tr>
                  );
                })}
              {item._source.manager &&
                Object.entries(item._source.manager).map((entry) => {
                  let key = entry[0];
                  let value = entry[1];
                  return (
                    <tr>
                      <td className="p-2">manager.{key}</td>
                      <td>{value}</td>
                    </tr>
                  );
                })}
              {item._source.rule && item._source.rule.mitre
                ? Object.entries(item._source.rule.mitre).map((entry) => {
                    let key = entry[0];
                    let value = entry[1];
                    return (
                      <tr>
                        <td className="p-2">rule.mitre.{key}</td>
                        {key === "technique" || key === "tactic" ? (
                          <td>{value.map((item) => item + " ")}</td>
                        ) : (
                          <td>{value}</td>
                        )}
                      </tr>
                    );
                  })
                : Object.entries(item._source.rule).map((entry) => {
                    let key = entry[0];
                    let value = entry[1];
                    return (
                      <tr>
                        <td className="p-2">rule.{key}</td>
                        {key === "groups" || key === "tsc" ? (
                          <td>{value.map((item) => item + " ")}</td>
                        ) : (
                          <td>{value}</td>
                        )}
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      );
    } else if (tableTab === "json") {
      return (
        <div>
          <pre>
            <code>{JSON.stringify(item._source, undefined, 2)}</code>
          </pre>
        </div>
      );
    } else if (tableTab === "rule") {
      return <div>RULE</div>;
    }
  };

  const showAlertsData = (alertsData) => {
    const indexOfLastPage = currentPage * postsPerPage;
    const indexOfFirstPage = indexOfLastPage - postsPerPage;
    const currentPosts = alertsData.slice(indexOfFirstPage, indexOfLastPage);

    try {
      return currentPosts.map((item, index) => {
        return (
          <>
            <tr
              key={index}
              onClick={() =>
                dropdownIndex === index
                  ? setDropdownIndex(-1)
                  : setDropdownIndex(index)
              }
              className={`border border-black text-center cursor-pointer ${
                dropdownIndex === index ? "bg-[aliceblue]" : null
              }`}
            >
              <td className="border border-black p-2">
                {dropdownIndex === index ? (
                  <i class="ri-arrow-down-wide-line"></i>
                ) : (
                  <i class="ri-arrow-right-wide-line"></i>
                )}
              </td>
              <td className="border border-black p-2">
                {new Date(item._source.timestamp)
                  .toLocaleString()
                  .replace(",", "")}
              </td>
              <td className="border border-black p-2 text-[#0078a3]">
                {item._source.agent ? item._source.agent.id : "-"}
              </td>
              <td className="border border-black p-2">
                {item._source.agent ? item._source.agent.name : "-"}
              </td>
              <td className="border border-black p-2">
                {item._source.rule.mitre && item._source.rule.mitre.technique
                  ? item._source.rule.mitre.technique.map((item) => item + " ")
                  : "-"}
              </td>
              <td className="border border-black p-2">
                {item._source.rule.mitre && item._source.rule.mitre.tactic
                  ? item._source.rule.mitre.tactic.map((item) => item + " ")
                  : "-"}
              </td>
              <td className="border border-black p-2">
                {item._source.rule ? item._source.rule.description : "-"}
              </td>
              <td className="border border-black p-2">
                {item._source.rule ? item._source.rule.level : "-"}
              </td>

              <td className="border border-black p-2">
                {item._source.rule.id}
              </td>
            </tr>
            {dropdownIndex === index ? (
              <tr>
                <td>
                  <div>
                    <div className="flex gap-6 px-12 py-4">
                      <div
                        className={`text-lg font-semibold ${
                          tableTab === "table"
                            ? "border-b-2 border-blue-400 text-blue-400"
                            : null
                        } py-2`}
                        onClick={() => setTableTab("table")}
                      >
                        Table
                      </div>
                      <div
                        className={`text-lg font-semibold ${
                          tableTab === "json"
                            ? "border-b-2 border-blue-400 text-blue-400"
                            : null
                        } py-2`}
                        onClick={() => setTableTab("json")}
                      >
                        JSON
                      </div>
                      <div
                        className={`text-lg font-semibold ${
                          tableTab === "rule"
                            ? "border-b-2 border-blue-400 text-blue-400"
                            : null
                        } py-2`}
                        onClick={() => setTableTab("rule")}
                      >
                        Rule
                      </div>
                    </div>

                    {showTableTabs(item)}
                  </div>
                </td>
              </tr>
            ) : (
              ""
            )}
          </>
        );
      });
    } catch (e) {
      alert(e.message);
    }
  };

  const showAlertsPagination = (alertsData) => {
    const totalPosts = alertsData.length;
    const totalPages = Math.ceil(totalPosts / postsPerPage);
    const pageNumbers = [];

    // Helper function to add page numbers
    const addPageNumber = (number) => {
      if (number > 0 && number <= totalPages && !pageNumbers.includes(number)) {
        pageNumbers.push(number);
      }
    };

    // Always show the first two pages
    addPageNumber(1);
    addPageNumber(2);

    // Show dots if needed
    if (currentPage > 3) {
      pageNumbers.push("..."); // Dots before the middle
    }

    // Show middle pages
    for (
      let i = Math.max(3, currentPage - 1);
      i <= Math.min(totalPages - 2, currentPage + 1);
      i++
    ) {
      addPageNumber(i);
    }

    // Show dots if needed
    if (currentPage < totalPages - 2) {
      pageNumbers.push("..."); // Dots after the middle
    }

    // Always show the last two pages
    addPageNumber(totalPages - 1);
    addPageNumber(totalPages);

    const handlePageChange = (number) => {
      if (number !== "...") {
        setCurrentPage(number);
      }
    };

    return (
      <div className="mt-5 mb-12">
        <ul className="flex gap-2 flex-wrap justify-center">
          {pageNumbers.map((number, index) => (
            <li key={index}>
              <button
                onClick={() => handlePageChange(number)}
                className={`px-2 py-1 text-sm min-w-8 rounded-sm ${
                  currentPage === number
                    ? "bg-[#05123A] text-white"
                    : "bg-gray-400 text-black"
                }`}
                disabled={number === "..."}
              >
                {number}
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const downloadAlertsExcel = (alertsData, type) => {
    let final = [];
    alertsData.map((item) =>
      final.push({
        TIMESTAMP: new Date(item._source.timestamp)
          .toLocaleString()
          .replace(",", ""),
        RULE: item._source.rule.id,
        DESCRIPTION: item._source.rule.description,
        LEVEL: item._source.rule.level,
        GROUPS: JSON.stringify(item._source.rule.groups),
        FULL_LOG: JSON.stringify(item._source),
      })
    );
    const worksheet = XLSX.utils.json_to_sheet(final);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${node}-${type}-alerts.xlsx`);
  };

  const showAlertsTable = (alertsData, type) => {
    if (allAlertsDataloading || scaAlertsDataloading)
      return (
        <div class="overflow-x-auto p-4">
          <div class="mb-4 text-center">
            <button class="w-48 h-12 bg-gray-300 rounded-md animate-pulse inline-block"></button>
          </div>

          <table class="min-w-full bg-white border border-gray-200">
            <thead class="bg-gray-200">
              <tr>
                <th class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </th>
                <th class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </th>
                <th class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </th>
                <th class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
              </tr>
              <tr>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
              </tr>
              <tr>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
              </tr>
              <tr>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
              </tr>
              <tr>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
              </tr>
              <tr>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
              </tr>
              <tr>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
              </tr>
              <tr>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
              </tr>
              <tr>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
              </tr>
              <tr>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
              </tr>
              <tr>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
              </tr>
              <tr>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
              </tr>
              <tr>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
                <td class="p-3">
                  <div class="h-4 bg-gray-300 rounded animate-pulse"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    return (
      <div className="flex flex-col">
        <button
          className={`px-4 py-2 rounded-md text-white bg-[#05123A]  self-center my-6 md:my-4 ${
            alertsData.length > 0 ? "cursor-pointer" : "opacity-20"
          }`}
          onClick={() => downloadAlertsExcel(alertsData, type)}
          disabled={alertsData.length <= 0}
        >
          Download As Excel
        </button>
        {alertsData?.length > 0 ? (
          <>
            <div className="h-[60vh] text-sm md:text-md overflow-auto">
              <table className="text-left border-separate border-spacing-0 border-black table-fixed w-full">
                <thead className="text-center border border-black sticky top-0 z-50 bg-gray-200">
                  <tr>
                    <th className="border border-black p-2 w-[30px] md:w-[50px]"></th>
                    <th className="border border-black p-2 w-[100px]">Time</th>
                    <th className="border border-black p-2 w-[60px] md:w-[100px]">
                      Agent
                    </th>
                    <th className="border border-black p-2 w-[150px]">
                      Agent Name
                    </th>
                    <th className="border border-black p-2 w-[150px]">
                      technique(s)
                    </th>
                    <th className="border border-black p-2 w-[150px]">
                      tactic(s)
                    </th>
                    <th className="border border-black p-2 w-[200px]">
                      Description
                    </th>
                    <th className="border border-black p-2 w-[80px]">Level</th>
                    <th className="border border-black p-2 w-[100px]">
                      Rule ID
                    </th>
                  </tr>
                </thead>
                <tbody>{showAlertsData(alertsData)}</tbody>
              </table>
            </div>
            <div>{showAlertsPagination(alertsData)}</div>
          </>
        ) : (
          <div className="flex justify-center items-center h-[50vh] text-red-400">
            No Data Found!
          </div>
        )}
      </div>
    );
  };

  const showAlertTabs = () => {
    return (
      <div className="flex justify-center items-center mt-12 xl:mt-16">
        <div
          className={`px-6 py-2 ${
            alertTab === 1 ? "bg-[#05123A] text-white" : "bg-gray-300"
          } cursor-pointer`}
          onClick={() => setAlertTab(1)}
        >
          All Alerts
        </div>
        <div
          className={`px-6 py-2 ${
            alertTab === 2 ? "bg-[#05123A] text-white" : "bg-gray-300"
          } cursor-pointer`}
          onClick={() => setAlertTab(2)}
        >
          SCA Alerts
        </div>
      </div>
    );
  };

  return (
    <div className="overflow-hidden lg:flex justify-between">
      <UserNavbar />

      {systemsData.length > 0 ? (
        <div className="px-4 lg:w-[80vw] xl:w-[85vw] lg:absolute lg:right-0 lg:px-6 xl:absolute xl:right-0 xl:px-16 xl:pt-20 xl:pb-12 h-full">
          <div className="flex gap-4 lg:gap-12 mt-6 md:mt-12 xl:mt-0 justify-center md:justify-start">
            <div className="flex flex-col md:flex-row gap-2 md:gap-4 items-center">
              <span className="text-xl md:text-3xl font-bold">System</span>
              <select
                value={node}
                onChange={handleChange}
                className="text-sm md:text-lg px-2 md:px-4 py-2 rounded-md bg-gray-600 text-white cursor-pointer"
              >
                <option value="">Select System</option>

                {systemsData.map((value, i) => {
                  return (
                    <option key={value.agentid} value={value.agentname}>
                      {value.agentname}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex flex-col md:flex-row gap-2 md:gap-4 items-center">
              <span className="text-xl md:text-3xl font-bold">TimeFrame</span>
              <select
                value={timeFrame}
                onChange={handleTimeFrameChange}
                className="text-sm md:text-lg px-2 md:px-4 py-2 rounded-md bg-gray-600 text-white cursor-pointer"
              >
                {timeFrameData.map((value, i) => {
                  return (
                    <option key={i} value={value}>
                      Last{" "}
                      {value.endsWith("h")
                        ? value.replace("h", "")
                        : value.replace("d", "")}{" "}
                      {value.endsWith("h") ? <>hour(s)</> : <>day(s)</>}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {node.length > 0 ? (
            <div>
              {allAlertsData !== "error" || scaAlertsData !== "error" ? (
                <>
                  {showAlertTabs()}
                  {alertTab === 1
                    ? showAlertsTable(allAlertsData, "all")
                    : showAlertsTable(scaAlertsData, "sca")}
                </>
              ) : (
                <div className="flex justify-center items-center text-red-400">
                  Error fetching data!
                </div>
              )}
            </div>
          ) : (
            <h3 className="px-4 gap-4 lg:w-[80vw] xl:w-[85vw] lg:absolute lg:right-0 lg:px-6 flex justify-center items-center xl:px-16 xl:pt-20 xl:pb-12 h-[80vh]">
              No agent selected!
            </h3>
          )}
        </div>
      ) : (
        <div>No Reports</div>
      )}
    </div>
  );
};

export default Reports;
