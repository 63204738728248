import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import UserNavbar from "./UserNavbar";
import { useNavigate } from "react-router-dom";

const AddCustomer = () => {
  const navigate = useNavigate();
  const token = Cookies.get("token");
  const role = Cookies.get("role");
  const [credentials, setCredentials] = useState({
    name: "",
    expiry: "",
    username: "",
    password: "",
    email: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    if (token && role) {
      if (role === "3") {
        navigate("/dashboard");
      }
    }
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/signin");
    } else {
      axios
        .get("/api/token/isLoggedIn", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => console.log(res.data))
        .catch(() => navigate("/signin"));
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, expiry, username, password, email } = credentials;
    axios
      .post(
        "/api/Customer",
        {
          name,
          expiry,
          username,
          password,
          email,
        },
        {
          headers: {
            "x-auth-token": token,
          },
        }
      )
      .then((res) => {
        if (res.data.message !== "Successfully Created") {
          setError(res.data.message);
          setTimeout(() => {
            setError("");
          }, 1000);
        } else {
          setCredentials({
            name: "",
            expiry: "",
            username: "",
            password: "",
            email: "",
          });
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        setError(err.response.data.message);
        setTimeout(() => {
          setError("");
        }, 1000);
      });
  };
  const handleChange = (e) => {
    e.preventDefault();
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <>
      <UserNavbar />
      <div className="px-4 gap-4 lg:w-[80vw] xl:w-[85vw] lg:absolute lg:right-0 lg:px-6 flex justify-center items-center xl:px-16 xl:pt-20 xl:pb-12 xl:bg-gray-50 h-[100vh]">
        <form onSubmit={handleSubmit} className="border border-black p-4 bg-white">
          <h2 className="text-lg font-semibold mb-4">Add Customer</h2>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-1">
              Name
              <input
                type="text"
                placeholder="Type customer name here | Should start with a letter, Ex: John doe"
                name="name"
                id="name"
                pattern="^[a-zA-Z]+.*$"
                value={credentials.name}
                onChange={handleChange}
                required
                className="mt-1 p-2 border border-gray-300 rounded w-full"
              ></input>
            </label>
          </div>
          <div className="mb-4">
            <label htmlFor="expirydate" className="block mb-1">
              Expiry Date
              <input
                type="text"
                placeholder="Type expiry date here"
                name="expiry"
                id="expiry"
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.min = new Date().toISOString().split("T")[0];
                  e.currentTarget.max = "2050-01-01";
                }}
                value={credentials.expiry}
                onChange={handleChange}
                required
                className="mt-1 p-2 border border-gray-300 rounded w-full"
              ></input>
            </label>
          </div>
          <div className="mb-4">
            <label htmlFor="username" className="block mb-1">
              Username
              <input
                type="text"
                placeholder="Type customer username here | Should start with a letter, Ex: johndoe"
                name="username"
                id="username"
                pattern="^[a-z|A-Z|]+[a-z|A-Z|0-9]*"
                value={credentials.username}
                onChange={handleChange}
                required
                className="mt-1 p-2 border border-gray-300 rounded w-full"
              ></input>
            </label>
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block mb-1">
              Password
              <input
                type="password"
                placeholder="Type customer password here"
                name="password"
                id="password"
                value={credentials.password}
                onChange={handleChange}
                required
                className="mt-1 p-2 border border-gray-300 rounded w-full"
              ></input>
            </label>
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-1">
              Email
              <input
                type="email"
                placeholder="Type customer email here"
                name="email"
                id="email"
                value={credentials.email}
                onChange={handleChange}
                required
                className="mt-1 p-2 border border-gray-300 rounded w-full"
              ></input>
            </label>
          </div>
          <div className="flex justify-between mt-8">
            <button
              type="button"
              className="bg-gray-400 text-white px-6 py-2 rounded-md hover:bg-red-600"
              onClick={() => navigate("/dashboard")}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="bg-[#030B3A] text-white px-6 py-2 rounded-md hover:bg-[#B2EBFF] hover:text-black"
            >
              Add Customer
            </button>
          </div>
        </form>
        {error && <div className="text-red-500 mt-2">{error}</div>}
      </div>
    </>
  );
};

export default AddCustomer;
