import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import UserNavbar from "./UserNavbar";
import { useNavigate } from "react-router-dom";
import { useDashboard } from "../contexts/DashboardContext";

const AddLicense = () => {
  const navigate = useNavigate();
  const token = Cookies.get("token");
  const role = Cookies.get("role");
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const { customers, setCustomers, licenses, setLicenses } = useDashboard();
  const [credentials, setCredentials] = useState({
    type: "",
    count: "",
    customerid: "",
    expiry: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    if (token && role) {
      if (role === "3") {
        navigate("/dashboard");
      }
    }
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/signin");
    } else {
      axios
        .get("/api/token/isLoggedIn", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => console.log(res.data))
        .catch(() => navigate("/signin"));
    }
  }, []);

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { count, expiry } = credentials;
    axios
      .post(
        "/api/Customer/licenses",
        {
          type,
          count,
          customerid: id,
          expiry,
        },
        {
          headers: {
            "x-auth-token": token,
          },
        }
      )
      .then(() => {
        setCredentials({
          type: "",
          count: "",
          customerid: "",
          expiry: "",
        });
        navigate("/dashboard");
      })
      .catch(() => {
        setError("Invalid Data");
        setTimeout(() => {
          setError("");
        }, 1000);
      });
  };
  useEffect(() => {
    if (!token) {
      navigate("/");
    } else {
      axios
        .get("/api/customer", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => {
          setCustomers(res.data.customers);
        })
        .catch(() => {
          navigate("/");
        });
    }
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/");
    } else {
      axios
        .get("/api/License", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => {
          setLicenses(res.data.data);
        })
        .catch(() => {
          navigate("/dashboard");
        });
    }
  }, []);

  const handleSystemId = (e) => {
    e.preventDefault();
    setId(e.target.value);
  };

  const handleType = (e) => {
    e.preventDefault();
    setType(e.target.value);
  };

  return (
    <>
      <UserNavbar />
      <div className="px-4 gap-4 lg:w-[80vw] xl:w-[85vw] lg:absolute lg:right-0 lg:px-6 flex justify-center items-center xl:px-16 xl:pt-20 xl:pb-12 xl:bg-gray-50 h-[100vh]">
        <form
          className="border border-black p-4 bg-white"
          onSubmit={handleSubmit}
        >
          <h2 className="text-lg font-semibold mb-4">Add License</h2>
          <div className="mb-4">
            <select
              className="text-sm md:text-lg px-1 py-1 rounded-md bg-gray-600 text-white cursor-pointer"
              value={type}
              onChange={handleType}
            >
              <option value="">Select License</option>
              {licenses.map((license, i) => {
                return (
                  <option key={license.type} value={license.type}>
                    {license.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-4">
            <input
              type="number"
              placeholder="Count"
              name="count"
              id="count"
              min="1"
              value={credentials.count}
              onChange={handleChange}
              required
              className="mt-1 p-2 border border-gray-300 rounded w-full"
            ></input>
          </div>
          <div className="mb-4">
            <select
              className="text-sm md:text-lg px-1 py-1 rounded-md bg-gray-600 text-white cursor-pointer"
              value={id}
              onChange={handleSystemId}
            >
              <option value="">Select Customer</option>
              {customers.map((customer, i) => {
                return (
                  <option key={customer.value} value={customer.value}>
                    {customer.label}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Expiry date"
              name="expiry"
              id="expiry"
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.min = new Date().toISOString().split("T")[0];
              }}
              value={credentials.expiry}
              onChange={handleChange}
              required
              className="mt-1 p-2 border border-gray-300 rounded w-full"
            ></input>
          </div>
          <div className="flex justify-between mt-8">
            <button
              type="button"
              className="bg-gray-400 text-white px-6 py-2 rounded-md hover:bg-red-600"
              onClick={() => navigate("/dashboard")}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="bg-[#030B3A] text-white px-6 py-2 rounded-md hover:bg-[#B2EBFF] hover:text-black"
            >
              Add License
            </button>
          </div>
        </form>
        {error && <div className="text-red-500 mt-2">{error}</div>}
      </div>
    </>
  );
};

export default AddLicense;
