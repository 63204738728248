import React, { useEffect, useState } from "react";
import UserNavbar from "./UserNavbar";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  CartesianGrid,
  XAxis,
  YAxis,
  AreaChart,
  Area,
  Label,
  ResponsiveContainer,
} from "recharts";
import Map from "./Map";
import { useDashboard } from "../contexts/DashboardContext";

const SystemDashboard = () => {
  const [systemsData, setSystemsData] = useState([]);
  const [node, setNode] = useState("");
  const [timeFrame, setTimeFrame] = useState("24h");
  const [selectedTab, setSelectedTab] = useState(1);
  const [isWindows, setIsWindows] = useState(false);
  const [agentInfo, setAgentInfo] = useState([]);
  const [statusInfo, setStatusInfo] = useState(["fetching...", "fetching..."]);
  const [allAlertsCount, setAllAlertsCount] = useState(0);
  const [gt10AlertsCount, setGt10AlertsCount] = useState(0);
  const [rootkitAlertsCount, setRootkitAlertsCount] = useState(0);
  const [fiimAlertsCount, setFiimAlertsCount] = useState(0);
  const [attackAlertsCount, setAttackAlertsCount] = useState(0);
  const [byLocationData, setByLocationData] = useState([]);
  const [byGroupData, setByGroupData] = useState([]);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [geoLocationData, setGeoLocationData] = useState([]);
  const [notScaAlertsData, setNotScaAlertsData] = useState([]);
  const [ipList, setIpList] = useState([]);
  const [activeLevel, setActiveLevel] = useState(null);
  const token = Cookies.get("token");
  const role = Cookies.get("role");
  const customerid = Cookies.get("customerid");
  const navigate = useNavigate();
  const params = useParams();
  const { setActiveNav } = useDashboard();

  let timeFrameData =
    selectedTab === 1
      ? ["1h", "12h", "24h", "7d"]
      : ["15m", "30m", "1h", "6h", "12h", "24h"];

  const getAlertsDataByTypeOrGroup = (category, subcategory) => {
    return axios.get(`/api/system/node/${category}/${node}/${subcategory}`, {
      params: {
        hours: timeFrame,
      },
      headers: {
        "x-auth-token": token,
      },
    });
  };

  const getAlertsDataByChartType = (chartType) => {
    return axios.get(`/api/system/node/${chartType}/${node}`, {
      params: {
        hours: timeFrame,
      },
      headers: {
        "x-auth-token": token,
      },
    });
  };

  useEffect(() => {
    if (!token) {
      navigate("/signin");
    } else {
      axios
        .get("/api/token/isLoggedIn", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => console.log(res.data))
        .catch(() => navigate("/signin"));
    }
  }, []);

  useEffect(() => {
    setActiveNav("system");
  }, []);

  useEffect(() => {
    axios
      .get(`/api/system/${customerid}`, {
        headers: {
          "x-auth-token": token,
        },
      })
      .then((res) => setSystemsData(res.data.systems))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (node?.length > 0) {
      axios
        .get(`/api/system/node/${node}/iswindows`, {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setIsWindows(res.data))
        .catch((err) => console.log(err));
    }
  }, [node]);

  useEffect(() => {
    if (node?.length > 0) {
      axios
        .get(`/api/system/node/${node}/agentinfo`, {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setAgentInfo(res.data))
        .catch((err) => console.log(err));
    }
  }, [node]);

  useEffect(() => {
    setNode(params.agent);
  }, []);

  useEffect(() => {
    if (!isWindows) {
      setSelectedTab(1);
    }
  }, [isWindows]);

  useEffect(() => {
    if (selectedTab === 1) {
      setTimeFrame("24h");
    } else {
      setTimeFrame("1h");
    }
  }, [selectedTab]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      axios
        .get(`/api/system/agentstatusinfo/${node}`, {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setStatusInfo(res.data));
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      getAlertsDataByTypeOrGroup("type", "all").then((res) =>
        setAllAlertsCount(res.data)
      );
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      getAlertsDataByTypeOrGroup("type", "gt10").then((res) =>
        setGt10AlertsCount(res.data)
      );
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      getAlertsDataByTypeOrGroup("type", "rootcheck").then((res) =>
        setRootkitAlertsCount(res.data)
      );
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      getAlertsDataByTypeOrGroup("type", "syscheck").then((res) =>
        setFiimAlertsCount(res.data)
      );
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      getAlertsDataByTypeOrGroup("type", "attack").then((res) =>
        setAttackAlertsCount(res.data)
      );
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      getAlertsDataByTypeOrGroup("group", "bygroup").then((res) =>
        setByGroupData(res.data)
      );
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      getAlertsDataByTypeOrGroup("group", "bylocation").then((res) =>
        setByLocationData(res.data)
      );
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      getAlertsDataByChartType("timeseries").then((res) => {
        const levels = new Set();
        res.data.forEach((item) => {
          Object.keys(item).forEach((key) => {
            if (key !== "time") levels.add(key); // Collect all levels
          });
        });

        const levelsArray = Array.from(levels);

        // Normalize res.data: Ensure each timestamp has all levels, even if some levels are missing
        const normalizedData = res.data.map((item) => {
          // Add missing levels as null (or 0)
          levelsArray.forEach((level) => {
            if (!item.hasOwnProperty(level)) {
              item[level] = null; // Or set it to 0 depending on the requirement
            }
          });
          return item;
        });

        setTimeSeriesData(normalizedData); // Set the normalized data to state
      });
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      getAlertsDataByChartType("notscatable").then((res) =>
        setNotScaAlertsData(res.data)
      );
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      getAlertsDataByChartType("map").then((res) =>
        setGeoLocationData(res.data)
      );
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      getAlertsDataByChartType("ip").then((res) => setIpList(res.data));
    }
  }, [node, timeFrame]);

  const handleChange = (event) => {
    setNode(event.target.value);
  };
  const handleTimeFrameChange = (event) => {
    setTimeFrame(event.target.value);
  };

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const now = new Date();
    const diffInHours = Math.abs(date - now) / (1000 * 60 * 60);

    if (diffInHours > 24) {
      // Return the date in DD/MM format with time in HH:mm
      return `${date.getDate().toString().padStart(2, "0")}/${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")} ${date.getHours().toString().padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    } else {
      // Return only the time in HH:mm format
      return `${date.getHours().toString().padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    }
  };

  const handleMouseEnter = (level) => {
    setActiveLevel(level); // Set the active level when hovering
  };

  const handleMouseLeave = () => {
    setActiveLevel(null); // Reset when mouse leaves
  };

  // Custom legend to show only the active level
  const customLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        className="recharts-default-legend"
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap", // This allows the legend items to wrap to the next line
          padding: 0, // Optional: Remove any default padding if necessary
          listStyle: "none", // Optional: Remove default list styles
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={index}
            style={{
              color: entry.color,
              fontWeight: entry.value === activeLevel ? "bold" : "normal", // Bold the active level
              scale: entry.value === activeLevel ? "1.1" : "1", // Bold the active level
              cursor: "pointer", // Make the legend items clickable
              marginRight: "15px", // Add spacing between legend items
              marginBottom: "5px", // Add spacing between rows (vertical gap)
              display: "flex",
              alignItems: "center", // Align icon and text horizontally
            }}
            onMouseEnter={() => handleMouseEnter(entry.value)} // Trigger hover effect
            onMouseLeave={handleMouseLeave} // Reset hover effect
          >
            <i class="ri-git-commit-line"></i>
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  const COLORS = [
    "#FF6F61", // Coral red
    "#4CAF50", // Green
    "#2196F3", // Blue
    "#FF9800", // Orange
    "#9C27B0", // Purple
    "#00BCD4", // Cyan
    "#212121", // Dark grey
    "#8E44AD", // Violet
    "#F39C12", // Amber
    "#2ECC71", // Emerald green
    "#E91E63", // Pink
    "#3F51B5", // Indigo
    "#212121", // Dark grey
    "#FF5722", // Deep orange
    "#9E9E9E", // Grey
    "#607D8B", // Blue-grey
    "#00BCD4", // Cyan
    "#FF1493", // Deep pink
    "#32CD32", // Lime green
    "#1E90FF", // Dodger blue
    "#D32F2F", // Red
    "#1976D2", // Blue
    "#388E3C", // Green
    "#0288D1", // Light blue
    "#FF4081", // Pink
    "#7B1FA2", // Deep purple
    "#212121", // Dark grey
    "#CD5C5C", // Indian red
    "#FF6347", // Tomato
    "#3F51B5", // Indigo
    "#FF4500", // Orange red
    "#F57C00", // Orange
    "#C2185B", // Pink
    "#7C4DFF", // Deep purple
    "#76FF03", // Lime green
    "#FF4081", // Pink
    "#00E5FF", // Bright cyan
    "#FF8A65", // Light orange
    "#00C853", // Green
    "#0288D1", // Light blue
    "#3D5AFE", // Blue
    "#F44336", // Red
    "#FF5722", // Deep orange
    "#9E9D24", // Olive green
    "#B3E5FC", // Light blue
    "#F44336", // Red
    "#FF9800", // Orange
    "#5C6BC0", // Indigo
    "#D32F2F", // Red
    "#009688", // Teal
    "#8E24AA", // Purple
    "#4CAF50", // Green
  ];

  const chartData =
    timeSeriesData !== "error" && timeSeriesData?.length > 0 && timeSeriesData;

  const showTabs = () => {
    if (isWindows)
      return (
        <div className="flex justify-center items-center mt-12 xl:mt-16">
          <div
            className={`px-6 py-2 ${
              selectedTab === 1 ? "bg-[#05123A] text-white" : "bg-gray-300"
            } cursor-pointer`}
            onClick={() => setSelectedTab(1)}
          >
            Alerts Monitoring
          </div>
          <div
            className={`px-6 py-2 ${
              selectedTab === 2 ? "bg-[#05123A] text-white" : "bg-gray-300"
            } cursor-pointer`}
            onClick={() => setSelectedTab(2)}
          >
            Health Monitoring
          </div>
        </div>
      );
  };

  const showAlertsMonitoringPanels = () => {
    const agent = agentInfo[0];

    const getRemixIcon = (platform) => {
      const icons = {
        windows: (
          <img
            src="https://cdn2.iconfinder.com/data/icons/social-icons-33/128/Windows_Phone-512.png"
            className="size-6 mr-2"
          />
        ),
        macos: (
          <img
            src="https://cdn3.iconfinder.com/data/icons/picons-social/57/16-apple-512.png"
            className="size-6 mr-2"
          />
        ),
        ubuntu: (
          <img
            src="https://cdn1.iconfinder.com/data/icons/operating-system-flat-1/30/ubuntu-512.png"
            className="size-6 mr-2"
          />
        ),
        linux: (
          <img
            src="https://cdn3.iconfinder.com/data/icons/logos-brands-3/24/logo_brand_brands_logos_linux-512.png"
            className="size-6 mr-2"
          />
        ),
        android: (
          <img
            src="https://cdn2.iconfinder.com/data/icons/social-icons-33/128/Android-512.png"
            className="size-6 mr-2"
          />
        ),
        ios: (
          <img
            src="https://cdn3.iconfinder.com/data/icons/picons-social/57/16-apple-512.png"
            className="size-6 mr-2"
          />
        ),
      };

      return (
        icons[platform] || (
          <img
            src="https://cdn1.iconfinder.com/data/icons/carbon-design-system-vol-6/32/not-available-512.png"
            className="size-6 mr-2"
          />
        )
      );
    };

    return (
      <div>
        <div className="flex">
          {agentInfo.length > 0 && (
            <div className="bg-white border flex flex-col gap-6 xl:flex-row w-full justify-between px-4 py-3 rounded-md shadow-md mt-4 mb-6">
              <div className="flex flex-col sm:gap-2">
                <div className="text-md">ID</div>
                <div className="font-semibold">{agent.id}</div>
              </div>

              <div className="flex flex-col sm:gap-2">
                <div className="text-md">Status</div>
                <div className="flex items-center font-semibold">
                  <div
                    className={`w-[10px] h-[10px] rounded-full mr-2 ${
                      agent.status === "active"
                        ? "bg-[#007872]"
                        : "bg-[#B52A22]"
                    }`}
                  ></div>
                  {agent.status}
                </div>
              </div>

              <div className="flex flex-col sm:gap-2">
                <div className="text-md">IP Address</div>
                <div className="font-semibold break-all">{agent.ip}</div>
              </div>

              <div className="flex flex-col gap-2">
                <div className="text-md">Operating System</div>
                <div className="font-semibold flex">
                  {agent.os
                    ? getRemixIcon(agent.os.platform.toLowerCase())
                    : ""}
                  {agent.os ? agent.os.name : "-"}{" "}
                  {agent.os ? agent.os.version : ""}
                </div>
              </div>

              <div className="flex flex-col sm:gap-2">
                <div className="text-md">Last Seen</div>
                <div className="font-semibold">
                  {agent.lastKeepAlive
                    ? new Date(agent.lastKeepAlive).toLocaleString()
                    : "-"}
                </div>
              </div>
            </div>
          )}
        </div>

        <div>
          <div className="md:flex lg:flex-col lg:items-center xl:flex-row">
            <div className="flex justify-between text-center gap-2 sm:flex-col sm:justify-center sm:w-[40%] sm:px-2 lg:flex-row lg:items-center lg:w-[60%] xl:w-[40%]">
              <div className="w-[50%] sm:w-full">
                <div className="font-semibold  xl:text-lg">Status</div>
                <div
                  className={`text-sm flex flex-col gap-4 ${
                    statusInfo[0] === "active" ? "bg-[#007872]" : "bg-[#B52A22]"
                  } text-white py-4 rounded-sm mt-2 md:text-lg md:py-8 lg:py-9 lg:text-2xl xl:py-10`}
                >
                  <div className="font-semibold">{statusInfo[0]}</div>
                  <div className="xl:text-xl break-all">{statusInfo[1]}</div>
                </div>
              </div>
              <div className="w-[50%] sm:w-full">
                <div className="font-semibold  xl:text-lg">Total Alerts</div>
                <div
                  className="text-2xl bg-[#B52A22] text-white py-7 rounded-sm mt-2 md:py-8 lg:py-12 lg:text-6xl cursor-pointer"
                  onClick={() =>
                    navigate(`/logs/node/${node}/all/${timeFrame}`)
                  }
                >
                  {allAlertsCount}
                </div>
              </div>
            </div>

            <div className="mt-6 sm:w-[60%] xl:w-[60%] sm:p-2">
              <div className="flex flex-wrap justify-between text-center gap-2 pt-4">
                <div className=" w-[48%] rounded-sm mb-4 lg:w-[48%] xl:w-[23%]  border border-black cursor-pointer">
                  <div className="font-bold text-black py-2 lg:text-xs xl:text-lg">
                    Alert Level &gt; 10
                  </div>
                  <div
                    className="text-4xl py-2 rounded-sm mt-2 text-red-500 font-extrabold sm:py-8 lg:text-5xl border-t border-black"
                    onClick={() =>
                      navigate(`/logs/node/${node}/gt10/${timeFrame}`)
                    }
                  >
                    {gt10AlertsCount}
                  </div>
                </div>
                <div className=" w-[48%] rounded-sm mb-4 lg:w-[48%] xl:w-[23%]  border border-black cursor-pointer">
                  <div className="font-bold text-black py-2 lg:text-xs xl:text-lg">
                    Rootkit Alerts
                  </div>
                  <div
                    className="text-4xl py-2 rounded-sm mt-2 text-red-500 font-extrabold sm:py-8 lg:text-5xl border-t border-black"
                    onClick={() =>
                      navigate(`/logs/node/${node}/rootcheck/${timeFrame}`)
                    }
                  >
                    {rootkitAlertsCount}
                  </div>
                </div>
                <div className=" w-[48%] rounded-sm mb-4 lg:w-[48%] xl:w-[23%]  border border-black cursor-pointer">
                  <div className="font-bold text-black py-2 lg:text-xs xl:text-lg">
                    FIIM Alerts
                  </div>
                  <div
                    className="text-4xl py-2 rounded-sm mt-2 text-red-500 font-extrabold sm:py-8 lg:text-5xl border-t border-black"
                    onClick={() =>
                      navigate(`/logs/node/${node}/syscheck/${timeFrame}`)
                    }
                  >
                    {fiimAlertsCount}
                  </div>
                </div>
                <div className=" w-[48%] rounded-sm mb-4 lg:w-[48%] xl:w-[23%]  border border-black cursor-pointer">
                  <div className="font-bold text-black py-2 lg:text-xs xl:text-lg">
                    Attack Alerts
                  </div>
                  <div
                    className="text-4xl py-2 rounded-sm mt-2 text-red-500 font-extrabold sm:py-8 lg:text-5xl border-t border-black"
                    onClick={() =>
                      navigate(`/logs/node/${node}/attack/${timeFrame}`)
                    }
                  >
                    {attackAlertsCount}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="xl:flex xl:flex-col">
            <div className="xl:flex">
              <div className="w-full xl:pr-16 xl:flex xl:flex-col xl:justify-evenly">
                <div className="relative w-full text-[0.5rem] xl:text-[1rem]">
                  {chartData !== "error" && chartData?.length > 0 ? (
                    <>
                      <ResponsiveContainer
                        aspect={1.75}
                        height="100%"
                        width="100%"
                        className="flex justify-center py-10"
                      >
                        <AreaChart data={chartData}>
                          <CartesianGrid stroke="#ccc" />
                          <XAxis
                            dataKey="time"
                            allowDuplicatedCategory={false}
                            tickFormatter={(tick) => formatDate(tick)} // Format to show only hours and minutes
                            type="category" // Treat X-axis as categorical (string-based dates)
                          />

                          <YAxis>
                            <Label
                              fill="black"
                              angle={270}
                              value="alerts count"
                              position={"insideLeft"}
                            />
                          </YAxis>
                          <Tooltip
                            labelFormatter={(value) => formatDate(value)}
                            content={({ active, payload, label }) => {
                              if (active && payload && payload.length) {
                                // Find the hovered level's data
                                const hoveredLevelData = payload.find(
                                  (item) => item.dataKey === activeLevel
                                );

                                if (hoveredLevelData) {
                                  const level = hoveredLevelData.dataKey; // Get the level (e.g., 'high', 'medium', 'low')
                                  const value = hoveredLevelData.value; // Get the value for that level

                                  return (
                                    <div
                                      className="custom-tooltip"
                                      style={{
                                        backgroundColor: "white",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <p>
                                        <strong>Time:</strong>{" "}
                                        {formatDate(label)}
                                      </p>
                                      <p>
                                        <strong>
                                          {level.charAt(0).toUpperCase() +
                                            level.slice(1)}
                                          :
                                        </strong>{" "}
                                        {value}
                                      </p>
                                    </div>
                                  );
                                }
                              }
                              return null;
                            }}
                          />
                          <Legend
                            content={customLegend}
                            layout="horizontal"
                            verticalAlign="top"
                            align="center"
                          />

                          {Object.keys(chartData[0])
                            .filter((key) => key !== "time")
                            .map((level, index) => {
                              const color = COLORS[index % COLORS.length]; // Get a color for each rule.level

                              return (
                                <Area
                                  key={level} // Use the level (e.g., 'high', 'medium', 'low') as the key
                                  type="monotone"
                                  dataKey={level} // Use the level to access data from chartData
                                  stroke={color} // Set the stroke color
                                  fill={color} // Set the fill color
                                  data={chartData} // Pass all data (no filtering)
                                  fillOpacity={
                                    level === activeLevel ? 0.75 : 0.25
                                  } // Highlight the hovered level
                                  onMouseEnter={() => handleMouseEnter(level)} // Trigger hover effect
                                  onMouseLeave={handleMouseLeave} // Reset on mouse leave
                                  cursor="pointer"
                                />
                              );
                            })}
                        </AreaChart>
                      </ResponsiveContainer>
                      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 mb-4">
                        <span className="text-black">time</span>
                      </div>
                    </>
                  ) : (
                    <div className="flex justify-center items-center text-red-400">
                      No Data Found!
                    </div>
                  )}
                </div>

                <div class="overflow-x-scroll overflow-y-auto max-h-[50vh] text-[0.75rem] xl:overflow-auto">
                  <table class="w-[100%] text-left border-separate border-spacing-0 border-black">
                    <thead className="border border-black sticky top-0 z-50 bg-gray-200">
                      <tr>
                        <th className="border border-black p-2">Rule</th>
                        <th className="border border-black p-2">Description</th>
                        <th className="border border-black p-2">Level</th>
                        <th className="border border-black p-2">Group</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notScaAlertsData !== "error" &&
                        notScaAlertsData?.length > 0 &&
                        notScaAlertsData.map((item, index) => {
                          return (
                            <tr key={index} className="border border-black">
                              <td className="border border-black p-2 text-[#0078a3]">
                                {item._source.rule.id}
                              </td>
                              <td className="border border-black p-2">
                                {item._source.rule.description}
                              </td>
                              <td className="border border-black p-2">
                                {item._source.rule.level}
                              </td>
                              <td className="border border-black p-2">
                                {item._source.rule.groups.map(
                                  (item) => item + " "
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="w-full md:flex md:justify-around xl:flex-col">
                <div className="flex flex-col items-center w-full md:w-1/2 xl:w-full">
                  <div className="my-2 xl:text-xl">Top Alert Groups</div>
                  <div className="w-full text-[0.5rem] xl:text-[1rem]">
                    {byGroupData !== "error" && byGroupData?.length > 0 ? (
                      <ResponsiveContainer aspect={1} width="100%" height={300}>
                        <PieChart>
                          <Legend
                            layout="vertical"
                            verticalAlign="top"
                            align="right"
                            wrapperStyle={{
                              fontSize: "0.5rem",
                              padding: "1rem",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              height: "20vh",
                              overflowY: "auto",
                            }}
                            content={(props) => {
                              const { payload } = props;
                              return (
                                <ul
                                  style={{
                                    listStyleType: "none",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  {payload.map((entry, index) => (
                                    <li
                                      key={`item-${index}`}
                                      style={{
                                        color: "black", // Set legend text color to black
                                        fontSize: "0.5rem", // Optional: adjust the font size if needed
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: "12px",
                                          height: "12px",
                                          backgroundColor: entry.payload.fill,
                                          marginRight: "8px",
                                        }}
                                      />
                                      {entry.value}
                                    </li>
                                  ))}
                                </ul>
                              );
                            }}
                          />
                          <Pie
                            dataKey="doc_count"
                            nameKey="key"
                            isAnimationActive={false}
                            data={byGroupData}
                            innerRadius="50%"
                            outerRadius="100%"
                            stroke=""
                            fill="#8884d8"
                            labelLine={false}
                          >
                            {byGroupData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>

                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    ) : (
                      <div className="flex justify-center items-center text-red-400">
                        No Data Found!
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex flex-col items-center w-full md:w-1/2 xl:w-full">
                  <div className="my-2 xl:text-xl">
                    Top Alert Groups by log file
                  </div>
                  <div className="w-full text-[0.5rem] xl:text-[1rem]">
                    {byLocationData !== "error" &&
                    byLocationData?.length > 0 ? (
                      <ResponsiveContainer aspect={1} width="100%" height={325}>
                        <PieChart>
                          <Legend
                            layout="vertical"
                            verticalAlign="top"
                            align="right"
                            wrapperStyle={{
                              fontSize: "0.5rem",
                              padding: "1rem",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              height: "20vh",
                              overflowY: "auto",
                            }}
                            content={(props) => {
                              const { payload } = props;
                              return (
                                <ul
                                  style={{
                                    listStyleType: "none",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  {payload.map((entry, index) => (
                                    <li
                                      key={`item-${index}`}
                                      style={{
                                        color: "black", // Set legend text color to black
                                        fontSize: "0.5rem", // Optional: adjust the font size if needed
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: "12px",
                                          height: "12px",
                                          backgroundColor: entry.payload.fill,
                                          marginRight: "8px",
                                        }}
                                      />
                                      {entry.value}
                                    </li>
                                  ))}
                                </ul>
                              );
                            }}
                          />
                          <Pie
                            dataKey="doc_count"
                            nameKey="key"
                            isAnimationActive={false}
                            data={byLocationData}
                            innerRadius="50%"
                            outerRadius="100%"
                            stroke=""
                            fill="#8884d8"
                            labelLine={false}
                          >
                            {byLocationData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>

                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    ) : (
                      <div className="flex justify-center items-center text-red-400">
                        No Data Found!
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full xl:flex xl:flex-col xl:items-center">
              <div className="my-2 xl:text-xl">Source IP Addresses</div>
              <div className="w-full">
                {geoLocationData !== "error" && geoLocationData?.length > 0 ? (
                  <Map geoLocationData={geoLocationData} />
                ) : (
                  <div className="flex justify-center items-center text-red-400">
                    No location Data Found!
                  </div>
                )}

                <div className="max-h-[20vh] overflow-auto mb-12 border border-black">
                  <ul>
                    {ipList !== "error" && ipList?.length > 0 ? (
                      ipList.map((ip) => (
                        <li className="border border-black p-2">
                          <a
                            href={`https://www.iplocation.net/ip-lookup/${ip}`}
                            target="blank"
                            title="Click to know more details"
                          >
                            {ip}
                          </a>
                        </li>
                      ))
                    ) : (
                      <div className="flex justify-center items-center text-red-400">
                        No IP Address Data Found!
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showHealthMonitoringPanels = () => {
    return (
      <div>
        <div>Work In Progress!</div>
        {/* <div style={{ display: "flex", marginBottom: "5rem" }}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                fontSize: "1.1rem",
              }}
            >
              Memory Usage
            </div>
            <iframe
              src={`${url}:3000/d-solo/Mir2RJHWz/ossec-summary?orgId=1&var-node=${node}&from=now-${timeFrame}&to=now&refresh=30s&theme=light&panelId=85`}
              style={{
                width: "40vw",
                height: "30vh",
                margin: "0 4rem 0 0",
              }}
              frameborder="0"
            ></iframe>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                fontSize: "1.1rem",
              }}
            >
              CPU Usage
            </div>
            <iframe
              src={`${url}:3000/d-solo/Mir2RJHWz/ossec-summary?orgId=1&var-node=${node}&from=now-${timeFrame}&to=now&refresh=30s&theme=light&panelId=86`}
              style={{
                width: "40vw",
                height: "30vh",
                margin: "0 4rem 0 0",
              }}
              frameborder="0"
            ></iframe>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                fontSize: "1.1rem",
              }}
            >
              Network Usage
            </div>
            <iframe
              src={`${url}:3000/d-solo/Mir2RJHWz/ossec-summary?orgId=1&var-node=${node}&from=now-${timeFrame}&to=now&refresh=30s&theme=light&panelId=87`}
              style={{
                width: "40vw",
                height: "30vh",
                margin: "0 4rem 0 0",
              }}
              frameborder="0"
            ></iframe>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                fontSize: "1.1rem",
              }}
            >
              Disk Available
            </div>
            <iframe
              src={`${url}:3000/d-solo/Mir2RJHWz/ossec-summary?orgId=1&var-node=${node}&from=now-${timeFrame}&to=now&refresh=30s&theme=light&panelId=88`}
              style={{
                width: "40vw",
                height: "30vh",
                margin: "0 4rem 0 0",
              }}
              frameborder="0"
            ></iframe>
          </div>
        </div> */}
      </div>
    );
  };

  return (
    <div className="overflow-hidden lg:flex justify-between">
      <UserNavbar />

      {systemsData.length > 0 ? (
        <div className="px-4 lg:w-[80vw] xl:w-[85vw] lg:absolute lg:right-0 lg:px-6 xl:absolute xl:right-0 xl:px-16 xl:pt-20 xl:pb-12 h-full">
          <div className="flex gap-4 lg:gap-12 mt-6 md:mt-12 xl:mt-0 justify-center md:justify-start">
            <div className="flex flex-col md:flex-row gap-2 md:gap-4 items-center">
              <span className="text-xl md:text-3xl font-bold">System</span>
              <select
                value={node}
                onChange={handleChange}
                className="text-sm md:text-lg px-2 md:px-4 py-2 rounded-md bg-gray-600 text-white cursor-pointer"
              >
                <option value="">Select System</option>

                {systemsData.map((value, i) => {
                  return (
                    <option key={value.agentid} value={value.agentname}>
                      {value.agentname}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex flex-col md:flex-row gap-2 md:gap-4 items-center">
              <span className="text-xl md:text-3xl font-bold">TimeFrame</span>
              <select
                value={timeFrame}
                onChange={handleTimeFrameChange}
                className="text-sm md:text-lg px-2 md:px-4 py-2 rounded-md bg-gray-600 text-white cursor-pointer"
              >
                {timeFrameData.map((value, i) => {
                  return (
                    <option key={i} value={value}>
                      Last {value}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {node ? (
            <div>
              {showTabs()}
              {selectedTab === 1
                ? showAlertsMonitoringPanels()
                : showHealthMonitoringPanels()}
            </div>
          ) : (
            <h3 className="px-4 gap-4 lg:w-[80vw] xl:w-[85vw] lg:absolute lg:right-0 lg:px-6 flex justify-center items-center xl:px-16 xl:pt-20 xl:pb-12 h-[80vh]">
              No agent selected!
            </h3>
          )}
        </div>
      ) : (
        <div className="px-4 gap-4 lg:w-[80vw] xl:w-[85vw] lg:absolute lg:right-0 lg:px-6 flex justify-center items-center xl:px-16 xl:pt-20 xl:pb-12 h-[80vh]">
          No Agents Added
        </div>
      )}
    </div>
  );
};

export default SystemDashboard;
